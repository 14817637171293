import ramadanMubarak from "./ramadan_mubarak_2023.jpg";
import ramadanTimeTable from "./ramadan_2023.jpg";
import freshDates from "./fresh_dates.jpg";

export const news = [
  {
    title: "Ramadan mubarak",
    date: "21 March 2023",
    description:
      " we will, insha'Allah, be starting 𝗥𝗮𝗺𝗮𝗱𝗮𝗻 on Thursday 23rd March 2023",
    image: ramadanMubarak,
    download: null,
  },
  {
    title: "Ramadan Taraweeh prayers",
    date: "21 March 2023",
    description:
      "Taraweeh prayers will therefore be Tomorrow 𝗡𝗜𝗚𝗛𝗧, 𝗪𝗲𝗱𝗻𝗲𝘀𝗱𝗮𝘆 𝟮𝟮𝗻𝗱 𝗠𝗮𝗿𝗰𝗵 at 8.15pm. There will also be a 𝗹𝗮𝘁𝗲 𝗻𝗶𝗴𝗵𝘁 𝗧𝗮𝗿𝗮𝘄𝗲𝗲𝗵 𝗽𝗿𝗮𝘆𝗲𝗿 𝗲𝘃𝗲𝗿𝘆𝗱𝗮𝘆 𝗮𝘁 11.00pm. 𝗢𝗻 𝗯𝗲𝗵𝗮𝗹𝗳 𝗼𝗳 West Wales Islamic Cultural Association, 𝘄𝗲 𝘄𝗶𝘀𝗵 𝗲𝘃𝗲𝗿𝘆𝗼𝗻𝗲 𝗮 𝗯𝗹𝗲𝘀𝘀𝗲𝗱 𝗥𝗮𝗺𝗮𝗱𝗮𝗻, 𝗺𝗮𝘆 𝗔𝗹𝗹𝗮𝗵 𝗦𝘂𝗯𝗵𝗮𝗻𝗮𝗵𝘂 𝗪𝗮𝘁𝗮 𝗔𝗹𝗹𝗮𝗵 𝗮𝗰𝗰𝗲𝗽𝘁 𝗮𝗹𝗹 𝗼𝘂𝗿 𝗲𝗳𝗳𝗼𝗿𝘁𝘀 𝗶𝗻 𝘁𝗵𝗶𝘀 𝗯𝗹𝗲𝘀𝘀𝗲𝗱 𝗺𝗼𝗻𝘁𝗵. 𝗔𝗺𝗲𝗲𝗻!",
    image: null,
    download: null,
  },
  {
    title: "Ramadan Timetable",
    date: "21 March 2023",
    description:
      "download the 𝗥𝗮𝗺𝗮𝗱𝗮𝗻 2023 𝗧𝗶𝗺𝗲𝘁𝗮𝗯𝗹𝗲 𝗳𝗿𝗼𝗺 here. Printed copies of the Timetable are available and can be collected from the Masjid.",
    image: ramadanTimeTable,
    download: ramadanTimeTable,
  },

  {
    title: "Fresh Dates",
    date: "7 March 2023",
    description:
      "Our 100% natural, preservative-free dates are a staple in Ramadan and out of Ramadan too. In addition, with every purchase, you're supporting your masjid",
    image: freshDates,
    download: null,
  },

  {
    title: "Community Development",
    date: "2 February 2022",
    description:
      "Insha’ Allah tomorrow after jummah, the Community Development Outreach Manager in the health board, will be visiting our masjid. Their team's role is to make sure that people from ethnic minority communities are treated fairly when they access healthcare.",
    image: null,
    download: null,
  },

  {
    title: "Madrassah Announcement",
    date: "25 December 2022",
    description:
      "Assalamu Alaikom everyone, I pray you are well Insha’a Allah. Unfortunately we are short of staff this Sunday 26th December due to our Ustadh Mainul Islam father's passed away and his Janazah is tomorrow in Bangladesh. So Insha’a Allah Islamiyah will be online this Weekend.",
    image: null,
    download: null,
  },

  {
    title: "Clocks go back an hour",
    date: "30 October 2021",
    description:
      "Don't forget to adjust your clocks this Sunday 31st October 2021. Please check out the updated timings of Salah (Jammaah).",
    image:
      "https://images.unsplash.com/photo-1589305732940-172deb4ce213?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80",
    download: null,
  },
];

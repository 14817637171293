import React from "react";
import "../styles/schoolPage.css";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { HiOutlineLocationMarker, HiClock } from "react-icons/hi";

class SchoolPage extends React.Component {
  constructor() {
    super();

    this.welcomeMessage = this.welcomeMessage.bind(this);
    this.courses = this.courses.bind(this);
  }

  welcomeMessage() {
    return (
      <div className="courses-message">
        <span>Madrasa</span>
        <span>Islamic Education</span>
        <span>
          Our supplementary school was created for you and is enriched by your
          presence and becomes more effective through your full support.
        </span>
      </div>
    );
  }


  courses() {
    return (
      <div className="courses">
        <Card>
          <Card.Body>
            <blockquote className="blockquote mb-0">
              <p className="courses-card-body">Islamic Education - Madrasa</p>
            </blockquote>
            <br></br>
            Our main objective is to offer a broad and balance Islamic
            curriculum. One of our aims is to teach your child to become a
            competent reader of the Holy Quran as well as building a solid
            foundation in understanding their faith.
          </Card.Body>

          <ListGroup variant="flush">
            <ListGroup.Item>
              <Badge pill bg="success">
                <HiOutlineLocationMarker></HiOutlineLocationMarker> Location
              </Badge>{" "}
              In the mosque
            </ListGroup.Item>

            <ListGroup.Item>
              <Badge pill bg="success">
                <HiClock></HiClock> Time
              </Badge>{" "}
              Sunday. 12 PM to 3 PM
            </ListGroup.Item>
          </ListGroup>
        </Card>

        <Card>
          <Card.Body>
            <blockquote className="blockquote mb-0">
              <p className="courses-card-body">Tajweed and Quran Recitation</p>
            </blockquote>
            <br></br>
            Learn Tajweed and correct your pronunciation and recitation from the
            comfort of your home.
          </Card.Body>

          <ListGroup variant="flush">
            <ListGroup.Item>
              <Badge pill bg="success">
                <HiOutlineLocationMarker></HiOutlineLocationMarker> Location
              </Badge>{" "}
              Online - Zoom
            </ListGroup.Item>

            <ListGroup.Item>
              <Badge pill bg="success">
                <HiClock></HiClock> Time
              </Badge>{" "}
              Mon and Wed. 6 PM to 7 PM
            </ListGroup.Item>
          </ListGroup>
        </Card>

        <Card>
          <Card.Body>
            <blockquote className="blockquote mb-0">
              <p className="courses-card-body">Memorizing the Quran</p>
            </blockquote>
            <br></br>
            Learn Tajweed and correct your pronunciation and recitation from the
            comfort of your home.
          </Card.Body>

          <ListGroup variant="flush">
            <ListGroup.Item>
              <Badge pill bg="success">
                <HiOutlineLocationMarker></HiOutlineLocationMarker> Location
              </Badge>{" "}
              Online - Zoom
            </ListGroup.Item>

            <ListGroup.Item>
              <Badge pill bg="success">
                <HiClock></HiClock> Time
              </Badge>{" "}
              Mon and Wed. 7 PM to 8 PM
            </ListGroup.Item>
          </ListGroup>
        </Card>
      </div>
    );
  }

  render() {
    return (
      <div className="school-continer">
        <div className="courses-top-continer"></div>
        <this.welcomeMessage></this.welcomeMessage>

        <this.courses></this.courses>
      </div>
    );
  }
}

export default SchoolPage;

import React from "react";

import "../styles/supportWWICA.css";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";

class SupportWWICA extends React.Component {
  constructor(props) {
    super(props);

    this.supportText = this.supportText.bind(this);
    this.supportHadith = this.supportHadith.bind(this);
    this.supportDonation = this.supportDonation.bind(this);
    this.amountAction = this.amountAction.bind(this);
    this.typeAction = this.typeAction.bind(this);
  }

  amountAction(amount) {
    console.log(amount);
  }

  typeAction() {}

  /**
   * display donation open message in a card. This will be the first card
   *
   * @returns donation open message
   */
  supportText() {
    return (
      <Card>
        <Card.Body>
          WWICA Islamic Centre & Masjid is in need of your help to continue its
          activities and projects that serve the whole community; so your money
          returns to you and you get the reward on top.
          <br></br> <br></br>
          Donating is easy and we provide you with a different ways to make your
          payment. Choose that which is more convenient to you.
          <br></br> <br></br>
          Jazak Allah Khairan.
        </Card.Body>
      </Card>
    );
  }

  /**
   * dispaly Hadith or Ayah in the a card. this will be the second card
   *
   * @returns donation hadith or ayah in a card
   */
  supportHadith() {
    return (
      <Card className="support-hadith">
        <div>
          The Messenger of Allah ﷺ said: “𝗪𝗵𝗼𝗲𝘃𝗲𝗿 𝗴𝗶𝘃𝗲𝘀 𝗳𝗼𝗼𝗱 𝗳𝗼𝗿 𝗮 𝗳𝗮𝘀𝘁𝗶𝗻𝗴
          𝗽𝗲𝗿𝘀𝗼𝗻 𝘁𝗼 𝗯𝗿𝗲𝗮𝗸 𝗵𝗶𝘀 𝗳𝗮𝘀𝘁, 𝗵𝗲 𝘄𝗶𝗹𝗹 𝗵𝗮𝘃𝗲 𝗮 𝗿𝗲𝘄𝗮𝗿𝗱 𝗹𝗶𝗸𝗲 𝘁𝗵𝗲𝗶𝗿𝘀, 𝘄𝗶𝘁𝗵𝗼𝘂𝘁
          𝘁𝗵𝗮𝘁 𝗱𝗲𝘁𝗿𝗮𝗰𝘁𝗶𝗻𝗴 𝗳𝗿𝗼𝗺 𝘁𝗵𝗲𝗶𝗿 𝗿𝗲𝘄𝗮𝗿𝗱 𝗶𝗻 𝘁𝗵𝗲 𝘀𝗹𝗶𝗴𝗵𝘁𝗲𝘀𝘁.”
        </div>

        <span>Sunan Ibn Majah 1746</span>
      </Card>
    );
  }

  /**
   * TODO: connect with paypal
   *
   * Display the donation button where the user can donate or explor other donation
   * options
   *
   * @returns donation card
   */
  supportDonation() {
    return (
      <Card>
        <Card.Body>
          <div className="support-donation">
            <div>
              Alhamdulillah WWICA will be hosting communal iftar this ramadan
              monday to friday. our kind and generous congregation are
              sponsoring iftar meals serving many in our community. it is indeed
              an excellent example of gaining rewards by helping with iftar for
              those who are fasting.
            </div>

            <div className="support-donation-bt">
              𝗧𝗼 𝗯𝗼𝗼𝗸 𝗮𝗻 𝗜𝗳𝘁𝗮𝗿 𝗼𝗿 𝗰𝗼𝗻𝘁𝗿𝗶𝗯𝘂𝘁𝗲 𝗶𝗻 𝗮 𝘀𝗵𝗮𝗿𝗲 𝗽𝗹𝗲𝗮𝘀𝗲 𝗰𝗼𝗻𝘁𝗮𝗰𝘁 shaikh
              Ali 𝗼𝗻 07821200521
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  }

  render() {
    return (
      <div className="support-wwica">
        <h5 className="layout-title">Support Your Masjid</h5>
        <div className="support-group">
        <this.supportDonation></this.supportDonation>
          <this.supportHadith></this.supportHadith>
          <this.supportText></this.supportText>
        </div>
      </div>
    );
  }
}

export default SupportWWICA;

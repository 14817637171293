import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Header from "./components/header";
import MainPage from "./pages/mainPage";

import "bootstrap/dist/css/bootstrap.min.css";

import Footer from "./components/footer";
import AboutPage from "./pages/aboutPage";
import Services from "./pages/servicesPage";
import SchoolPage from "./pages/schoolPage";

function App() {
  return (
    <div className="App">
      <div className="header">
        <Header></Header>
      </div>
      <BrowserRouter>
        <div className="main">
          <Routes>
            <Route
              index
              element={<MainPage></MainPage> }
            />
            <Route
              path="about"
              element={<AboutPage></AboutPage>}
            />
            <Route
              path="services"
              element={<Services></Services>}
            />
            <Route path="school" 
            element={<SchoolPage></SchoolPage>} 
            />
            <Route
              path="*"
              element={<div> No page, or not implemented yet 🏗️</div>}
            />
          </Routes>
          
          <Footer></Footer>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
